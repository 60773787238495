/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */

@tailwind base;
@tailwind components;
@tailwind utilities;





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --dark-jungle-green: hsl(188, 63%, 7%);
  --prussian-blue: hsl(200, 69%, 14%);
  --raisin-black-1: hsl(227, 29%, 13%);
  --raisin-black-2: hsl(229, 17%, 19%);
  --yellow-green: hsl(89, 72%, 45%);
  --orange-soda: hsl(9, 100%, 62%);
  --cultured-1: hsl(0, 0%, 93%);
  --cultured-2: hsl(192, 24%, 96%);
  --misty-rose: hsl(7, 56%, 91%);
  --alice-blue: hsl(210, 100%, 97%);
  --seashell: hsl(8, 100%, 97%);
  --cadet: hsl(200, 15%, 43%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --opal: hsl(180, 20%, 62%);

  /**
   * typography
   */

  --ff-nunito-sans: "Nunito Sans", sans-serif;
  --ff-poppins: "Poppins", sans-serif;

  --fs-1: 1.875rem;
  --fs-2: 1.5rem;
  --fs-3: 1.375rem;
  --fs-4: 1.125rem;
  --fs-5: 0.875rem;
  --fs-6: 0.813rem;
  --fs-7: 0.75rem;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /**
   * transition
   */

  --transition: 0.25s ease;

  /**
   * spacing
   */

  --section-padding: 100px;

  /**
   * shadow
   */

  --shadow-1: 0 5px 20px 0 hsla(219, 56%, 21%, 0.1);
  --shadow-2: 0 16px 32px hsla(188, 63%, 7%, 0.1);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a { text-decoration: none; }

a,
img,
span,
button,
ion-icon { display: block; }

button {
  border: none;
  background: none;
  font: inherit;
  text-align: left;
  cursor: pointer;
}

address { font-style: normal; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-nunito-sans);
  scroll-behavior: smooth;
}

body {
  background: var(--white);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
}

::-webkit-scrollbar-track { background: var(--white); }

::-webkit-scrollbar-thumb {
  background: var(--cadet);
  border-left: 2px solid var(--white);
}





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

button, a { transition: var(--transition); }

.h1,
.h2,
.h3 {
  color: var(--dark-jungle-green);
  font-family: var(--ff-poppins);
  line-height: 1.3;
}

.h1 {
  font-size: var(--fs-1);
  line-height: 1;
}

.h2 { font-size: var(--fs-2); }

.h3 {
  font-size: var(--fs-4);
  font-weight: var(--font-weight, 700);
}

.h3 > a { color: inherit; }

.btn {
  position: relative;
  background: var(--orange-soda);
  color: var(--white);
  font-family: var(--ff-poppins);
  font-size: var(--fs-5);
  text-transform: var(--text-transform, capitalize);
  border: 1px solid var(--orange-soda);
  padding: 10px 20px;
  z-index: 1;
}

.btn:is(:hover, :focus) {
  background: var(--black);
  color: var(--dark-jungle-green);
  border-color: var(--black);
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--white);
  transition: var(--transition);
  z-index: -1;
}

.btn:is(:hover, :focus)::before { width: 100%; }

.w-100 { width: 100%; }

.section-subtitle {
  color: var(--orange-soda);
  font-size: 22px;
  font-weight: var(--fw-600);
  padding: 5px 20px;
  background: hsla(9, 100%, 62%, 0.1);
  width: max-content;
  border-radius: 50px;
  margin-inline: auto;
  margin-bottom: 40px;
}

.section-title {
  text-align: var(--text-align, center);
  margin-bottom: var(--margin-bottom, 50px);
}

.card-badge {
  background: var(--black);
  color: var(--white);
  font-size: var(--fs-7);
  text-transform: uppercase;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 4px 10px;
}

.card-badge.green { background: var(--yellow-green); }

.card-badge.orange { background: var(--orange-soda); }

.has-scrollbar {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  overflow-x: auto;
  margin-inline: -15px;
  padding-inline: 15px;
  scroll-padding-left: 15px;
  padding-bottom: 60px;
  scroll-snap-type: inline mandatory;
}

.has-scrollbar > li {
  min-width: 100%;
  scroll-snap-align: start;
}

.has-scrollbar::-webkit-scrollbar-track {
  background: var(--cultured-2);
  outline: 2px solid var(--cadet);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-thumb {
  background: var(--cadet);
  border: 1px solid var(--cultured-2);
  border-radius: 10px;
}

.has-scrollbar::-webkit-scrollbar-button { width: 15%; }





/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header {
  position: relative;
  z-index: 2;
}

.header-top {
  background: var(--prussian-blue);
  padding-block: 15px;
}

.header-top .container,
.header-top-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.header-top .container { gap: 8px 20px; }

.header-top-list { gap: 15px; }

.header-top-link {
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.header-top-link:is(:hover, :focus) { color: var(--orange-soda); }

.header-top-link ion-icon {
  color: var(--orange-soda);
  font-size: 15px;
  --ionicon-stroke-width: 60px;
}

.header-top .wrapper,
.header-top-social-list {
  display: flex;
  align-items: center;
}

.header-top .wrapper { gap: 20px; }

.header-top-social-list { gap: 8px; }

.header-top-social-link {
  color: var(--white);
  font-size: 15px;
}

.header-top-btn {
  background: var(--orange-soda);
  color: var(--white);
  font-size: var(--fs-6);
  font-weight: var(--fw-700);
  padding: 4px 15px;
}

.header-top-btn:is(:hover, :focus) { --orange-soda: hsl(7, 72%, 46%); }

.header-bottom {
  background: var(--white);
  padding-block: 25px;
}

.header-bottom .logo img { margin-inline: auto; }

.navbar {
  background: var(--white);
  position: fixed;
  top: 0;
  left: -310px;
  max-width: 300px;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 10px hsla(0, 0%, 0%, 0.3);
  z-index: 5;
  padding: 60px 20px;
  visibility: hidden;
  transition: 0.15s ease-in;
}

.navbar.active {
  visibility: visible;
  transform: translateX(310px);
  transition: 0.25s ease-out;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--cultured-1);
  margin-bottom: 25px;
}

.navbar-top .logo img { width: 200px; }

.nav-close-btn ion-icon {
  font-size: 20px;
  --ionicon-stroke-width: 45px;
  padding: 5px;
}

.navbar-link {
  color: var(--cadet);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  text-transform: uppercase;
  padding-block: 15px;
}

.navbar-link:is(:hover, :focus) { color: var(--orange-soda); }

.overlay {
  position: fixed;
  inset: 0;
  background: hsla(0, 0%, 0%, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  z-index: 4;
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.header-bottom-actions {
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-block: 15px 10px;
  box-shadow: -2px 0 30px hsla(237, 71%, 52%, 0.2);
  z-index: 3;
}

.header-bottom-actions-btn ion-icon {
  color: hsl(0, 0%, 10%);
  font-size: 20px;
  margin-inline: auto;
  margin-bottom: 5px;
  --ionicon-stroke-width: 40px;
  transition: var(--transition);
}

.header-bottom-actions-btn:is(:hover, :focus) ion-icon { color: var(--orange-soda); }

.header-bottom-actions-btn span {
  color: var(--cadet);
  font-family: var(--ff-poppins);
  font-size: var(--fs-7);
  font-weight: var(--fw-500);
}


/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero {
  background: var(--cultured-2);
  padding-top: 50px;
  padding-bottom: 200px;
}

.hero-content { margin-bottom: 60px; }

.hero-subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}

.hero-subtitle ion-icon { color: var(--orange-soda); }

.hero-subtitle span {
  color: var(--dark-jungle-green);
  font-size: var(--fs-5);
  font-weight: var(--fw-700);
}

.hero-title { margin-bottom: 20px; }

.hero-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  line-height: 1.8;
  padding-left: 15px;
  border-left: 1px solid;
  margin-bottom: 30px;
}


/*-----------------------------------*\
  #ABOUT
\*-----------------------------------*/

.about { padding-block: var(--section-padding); }

.about-banner {
  position: relative;
  margin-bottom: 40px;
}

.about-banner > img {
  max-width: max-content;
  width: 100%;
}

.about-banner .abs-img {
  position: absolute;
  bottom: 100px;
  left: 15px;
  width: 50%;
  border-radius: 4px;
}

.about .section-subtitle { margin-inline: 0; }

.about .section-title {
  --text-align: left;
  --margin-bottom: 15px;
}

.about-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  line-height: 1.7;
  margin-bottom: 30px;
}

.about-list { margin-bottom: 30px; }

.about-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.about-item-icon {
  background: var(--misty-rose);
  height: 45px;
  min-width: 45px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.about-item-icon ion-icon {
  color: var(--orange-soda);
  font-size: 18px;
}

.about-item-text {
  color: var(--cadet);
  font-size: var(--fs-5);
}

.about .callout {
  background: hsla(7, 78%, 53%, 0.05);
  color: var(--cadet);
  font-size: var(--fs-5);
  font-weight: var(--fw-500);
  line-height: 1.8;
  padding: 20px 25px;
  border-left: 4px solid var(--orange-soda);
  margin-bottom: 40px;
}

.about .btn {
  max-width: max-content;
  --text-transform: uppercase;
}



/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/


.service {
  background: var(--cultured-2);
  padding-block: var(--section-padding);
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  position: relative;
  background: var(--white);
  text-align: center;
  padding: 5px 1px;
  box-shadow: var(--shadow-2);
}
.service-list > li { width: calc(80%)}; 

.service-card .card-icon {
  width: max-content;
  margin-inline: auto;
  margin-bottom: 20px;
}

.service-card .card-title { margin-bottom: 8px; }

.service-card .card-title > a:is(:hover, :focus) { color: var(--orange-soda); }

.service-card .card-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  line-height: 1.8;
  margin-bottom: 25px;
}

.service-card .card-link {
  color: var(--opal);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.service-card:is(:hover, :focus) .card-link { color: var(--orange-soda); }

.service-card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px;
  background: var(--orange-soda);
  transition: var(--transition);
}

.service-card:is(:hover, :focus)::after { width: 100%; }


/*-----------------------------------*\
  #PROPERTY
\*-----------------------------------*/

.property { padding-block: var(--section-padding); }

.property-card {
  border: 1px solid var(--alice-blue);
  box-shadow: var(--shadow-2);
}

.property-card .card-banner {
  position: relative;
  aspect-ratio: 2 / 1.5;
  overflow: hidden;
}

.property-card .card-banner a { height: 100%; }

.property-card .card-banner img {
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
}

.property-card:hover .card-banner img { transform: scale(1.1); }

.property-card .card-banner::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, hsla(0, 0%, 0%, 0.95), transparent 30%);
  pointer-events: none;
  z-index: 1;
}

.property-card .banner-actions {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 15px 10px;
  z-index: 1;
}

.banner-actions-btn {
  color: var(--white);
  font-size: var(--fs-6);
  line-height: 1;
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.banner-actions-btn ion-icon { font-size: 16px; }

.banner-actions-btn:first-child { margin-right: auto; }

.banner-actions-btn:is(:hover, :focus) { color: var(--orange-soda); }

.property-card .card-content {
  padding: 30px 15px 15px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
}

.card-price {
  color: var(--orange-soda);
  font-family: var(--ff-poppins);
  font-size: var(--fs-5);
  margin-bottom: 5px;
}

.card-price strong {
  font-size: var(--fs-4);
  font-weight: var(--fw-600);
}

.property-card .card-title {
  --font-weight: var(--fw-600);
  margin-bottom: 15px;
}

.property-card .card-title > a:is(:hover, :focus) { color: var(--orange-soda); }

.property-card .card-text {
  color: var(--cadet);
  font-size: var(--fs-5);
  line-height: 1.8;
  margin-bottom: 25px;
}

.property-card .card-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 15px;
}

.property-card .card-item {
  padding-block: 5px;
  color: var(--cadet);
  font-size: var(--fs-5);
}

.property-card .card-item:not(:last-child) {
  padding-right: 15px;
  border-right: 1px solid hsla(0, 0%, 0%, 0.2);
  margin-right: 20px;
}

.property-card .card-item :is(strong, ion-icon) { display: inline-block; }

.property-card .card-item ion-icon {
  margin-left: 2px;
  margin-bottom: -2px;
}

.property-card .card-item span { margin-top: 5px; }

.card-footer {
  padding: 15px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.card-author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.author-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}

.author-name > a {
  color: var(--dark-jungle-green);
  font-family: var(--ff-poppins);
  font-size: var(--fs-5);
  font-weight: var(--fw-600);
  margin-bottom: 3px;
}

.author-name > a:is(:hover, :focus) { color: var(--orange-soda); }

.author-title {
  color: var(--cadet);
  font-size: var(--fs-7);
}

.card-footer-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-footer-actions-btn {
  background: var(--cultured-2);
  color: var(--cadet);
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  font-size: 18px;
}

.card-footer-actions-btn:is(:hover, :focus) {
  background: var(--orange-soda);
  color: var(--white);
}


/*-----------------------------------*\
  #FEATURES
\*-----------------------------------*/

.features {
  background: var(--cultured-2);
  padding-block: var(--section-padding);
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px 20px;
}

.features-list > li { width: calc(50% - 10px); }

.features-card {
  position: relative;
  background: var(--white);
  padding: 40px 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: var(--shadow-1);
}

.features-card:is(:hover, :focus) { background: var(--orange-soda); }

.features-card .card-icon {
  background: var(--seashell);
  color: var(--orange-soda);
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 28px;
}

.features-card .card-icon ion-icon { --ionicon-stroke-width: 20px; }

.features-card .card-title {
  color: var(--dark-jungle-green);
  font-family: var(--ff-poppins);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  text-align: center;
  transition: var(--transition);
}

.features-card:is(:hover, :focus) .card-title { color: var(--white); }

.features-card .card-btn {
  background: var(--white);
  color: var(--cadet);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  font-size: 20px;
  border-radius: 50%;
  box-shadow: 0 0 10px hsla(219, 56%, 21%, 0.1);
  transition: var(--transition);
}

.features-card:is(:hover, :focus) .card-btn { color: var(--orange-soda); }



/*-----------------------------------*\
  #BLOG
\*-----------------------------------*/

.blog { padding-block: var(--section-padding); }

.blog-card {box-shadow: var(--shadow-2); }

.blog-card .card-banner {
  position: relative;
  overflow: hidden;
  aspect-ratio: 2 / 1.5;
}

.blog-card .card-banner::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, hsla(0, 0%, 0%, 0.95), transparent 30%);
  pointer-events: none;
  z-index: 1;
}

.blog-card .banner-actions {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 15px 10px;
  z-index: 1;
}

.blog-card .card-banner img {
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease;
}

.blog-card:is(:hover, :focus) .card-banner img { transform: scale(1.1); }

.blog-content { padding: 30px; }

.blog-card .card-meta-list {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.blog-card :is(.card-meta-link, .publish-date) {
  color: var(--cadet);
  font-size: var(--fs-7);
  font-weight: var(--fw-600);
  display: flex;
  align-items: center;
  gap: 5px;
  transition: var(--transition);
}

.blog-card :is(.card-meta-link, .publish-date) ion-icon { color: var(--orange-soda); }

.blog-card .card-meta-link:is(:hover, :focus) { color: var(--orange-soda); }

.blog-title {
  font-size: 1rem;
  --font-weight: var(--fw-600);
}

.blog-card .blog-title:is(:hover, :focus) { color: var(--orange-soda); }

.blog-content-bottom {
  padding-top: 20px;
  border-top: 1px solid hsla(0, 0%, 0%, 0.1);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-card .read-more-btn {
  color: var(--orange-soda);
  font-size: var(--fs-7);
  font-weight: var(--fw-600);
  text-transform: uppercase;
}


/*-----------------------------------*\
  #CTA
\*-----------------------------------*/

.cta {
  background: linear-gradient(to bottom, var(--white) 50%, var(--raisin-black-1) 50%);
}

.cta-card {
  background: var(--orange-soda);
  padding: 40px 30px;
  box-shadow: var(--shadow-2);
}

.cta-card .card-content {
  max-width: max-content;
  padding-left: 0px;
  margin-bottom: 30px;
}

.cta-card .card-title {
  color: var(--white);
  line-height: 1.3;
  margin-bottom: 15px;
}

.cta-card .card-text {
  color: var(--white);
  font-size: var(--fs-5);
  line-height: 1.8;
}

.cta-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--black);
  background: var(--white);
  box-shadow: var(--shadow-2);
  border-color: var(--white);
  margin-inline: auto;
}

.cta-btn:is(:hover, :focus) {
  background: none;
  color: var(--white);
  border-color: transparent;
}

.cta-btn::before { background: var(--black); }


/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer {
  background: var(--raisin-black-1);
  color: var(--white);
  margin-bottom: 68px;
}

.footer .container { padding-inline: 30px; }

.footer a { color: inherit; }

.footer-top {
  padding-top: 80px;
  padding-bottom: 40px;
}

.footer-brand { margin-bottom: 70px; }

.footer-brand .logo { margin-bottom: 15px; }

.section-text {
  font-size: var(--fs-5);
  line-height: 1.8;
  margin-bottom: 30px;
  max-width: 45ch;
}

.contact-list {
  display: grid;
  gap: 15px;
  margin-bottom: 30px;
}

.contact-link {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.contact-link ion-icon { font-size: 18px; }

.contact-link :is(address, span) {
  font-size: var(--fs-5);
  transition: var(--transition);
}

.contact-link:is(:hover, :focus) span { color: var(--orange-soda); }

.social-list {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-list:not(:last-child) { margin-bottom: 50px; }

.footer-list-title {
  font-family: var(--ff-poppins);
  font-size: var(--fs-3);
  font-weight: var(--fw-700);
  margin-bottom: 15px;
}

.footer-link {
  font-size: var(--fs-5);
  padding-block: 10px;
}

.footer-link:is(:hover, :focus) { color: var(--orange-soda); }

.footer-bottom {
  background: var(--raisin-black-2);
  padding-block: 25px;
}

.copyright {
  font-size: var(--fs-5);
  text-align: center;
}

.copyright a { display: inline-block; }

.copyright a:is(:hover, :focus) { color: var(--orange-soda); }
.hero .container {
  height: 400px;
}


/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 600px screen
 */

@media (min-width: 600px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-2: 1.875rem;

  }


  /**
   * REUSED STYLE
   */

  .container {
    max-width: 550px;
    margin-inline: auto;
  }

  .has-scrollbar {
    gap: 25px;
    margin-inline: -25px;
    padding-inline: 25px;
    scroll-padding-left: 25px;
  }

  .has-scrollbar > li { min-width: calc(50% - 12.5px); }


  /**
   * SERVICE
   */

  .service-list > li { width: calc(50% - 15px); }


  /**
   * PROPERTY
   */

  .property .container {
    max-width: unset;
    padding-inline: 25px;
  }


  /**
   * CTA
   */

  .cta-card { --fs-2: 1.5rem; }
  .cta-card .card-content {
    padding-left: 0px;
  }



  /**
   * FOOTER
   */

  .footer-link-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

}


/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 2.5rem;
    --fs-5: 0.938rem;
    --fs-6: 0.875rem;

  }


  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }

  .btn {
    --fs-5: 1rem;
    padding: 12px 28px;
  }


  /**
   * HEADER
   */

  .header-top { padding-block: 5px; }

  .header-top .wrapper { margin-left: auto; }

  .header-top-social-list { gap: 12px; }

  .header-top-social-link { font-size: 1rem; }

  .header-top-btn { padding: 10px 20px; }

  .header-bottom-actions {
    all: unset;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-bottom-actions-btn ion-icon { margin-bottom: 0; }

  .header-bottom-actions-btn span { display: none; }

  .header-bottom-actions-btn {
    background: var(--white);
    width: 50px;
    height: 50px;
    box-shadow: var(--shadow-2);
  }

  /**
   * HERO
   */

  .hero-content { max-width: 400px; }
  .hero .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 50px;
    height: 600px;
  }

  /**
   * ABOUT
   */

  .about .section-title { max-width: 30ch; }

  .about-text { max-width: 55ch }

  .about-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /**
   * FEATURES
   */

  .features-list > li { width: calc(33.33% - 13.33px); }

  .features-card { gap: 20px; }

  .features-card .card-icon {
    width: 80px;
    height: 80px;
    font-size: 32px;
  }

  .features-card .card-title { --fs-6: 1.125rem; }

  /**
   * BLOG
   */

  .blog-card { --fs-7: 0.875rem; }

  .blog-title { font-size: 1.25rem; }

  .blog .card-meta-list { gap: 30px; }

  /**
   * CTA
   */

  .cta-card { --fs-2: 1.625rem; }
  .cta-card .card-content {
    padding-left: 0px;
  }

  /**
   * FOOTER
   */

  .footer { margin-bottom: 0; }

}


/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 3.125rem;
    --fs-4: 1.375rem;

  }


  /**
   * REUSED STYLE
   */

  .container { max-width: 970px; }

  .btn { padding: 15px 40px; }

  /**
   * HEADER
   */

  .header-top-list,
  .header-top .wrapper { gap: 30px; }

  /**
   * HERO
   */

  .hero-content {
    max-width: unset;
    margin-bottom: 0;
  }

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 50px;
    height: 700px;
  }

  /**
   * ABOUT
   */

  .about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 60px;
  }

  .about-banner { margin-bottom: 0; }

  .about-banner > img { width: 100%; }

  /**
   * SERVICE
   */

  .service-list > li { width: calc(25% - 40px); }

  /**
   * PROPERTY
   */

  .property-card .card-content { padding-inline: 30px; }

  .card-footer { padding: 20px 30px 30px; }

  /**
   * FEATURES
   */

  .features-list { column-gap: 30px; }

  .features-list > li { width: calc(25% - 30px); }

  /**
   * BLOG
   */

  .blog-title { font-size: 1.375rem; }

  /**
   * CTA
   */

  .cta-card {
    --fs-2: 1.875rem;
    --fs-5: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }

  .cta-card :is(.card-content, .cta-btn) { margin: 0; }

  /**
   * FOOTER
   */

  .footer-top .container {
    display: flex;
    justify-content: space-between;
  }

  .footer-brand {
    max-width: 300px;
    margin-bottom: 0;
  }

  .footer-link-box { flex-basis: 550px; }

}



/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-2: 2.75rem;
    --fs-4: 1.5rem;

  }

  /**
   * REUSED STYLE
   */

  .container { max-width: 1200px; }

  .has-scrollbar > li { min-width: calc(33.33% - 16.66px); }


  /**
   * HEADER
   */

  .header-bottom { padding-block: 30px; }

  .header-bottom-actions-btn:last-child,
  .navbar-top,
  .overlay { display: none; }

  .navbar,
  .navbar.active { all: unset; }

  .navbar-list {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .navbar-link {
    color: var(--dark-jungle-green);
    --fs-5: 1.125rem;
    text-transform: capitalize;
  }

  .header { padding-bottom: 114px; }

  .header-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .header.active .header-bottom {
    position: fixed;
    bottom: auto;
    top: -94px;
    padding-block: 20px;
    box-shadow: 0 10px 50px hsla(237, 71%, 52%, 0.2);
    animation: slideDown 0.25s ease-out forwards;
  }

  @keyframes slideDown {
    0% { transform: translateY(0); }
    100% { transform: translateY(100%); }
  }


  /**
   * HERO
   */

  .hero-text {
    padding-left: 30px;
    max-width: 450px;
    margin-bottom: 40px;
  }


  /**
   * FEATURES
   */

  .features-card .card-icon {
    width: 100px;
    height: 100px;
    font-size: 45px;
  }

  .features-card .card-title { --fs-6: 1.375rem; }


  /**
   * CTA
   */

  .cta-card {
    --fs-2: 2.25rem;
    padding-inline: 60px;
  }
  .cta-card .card-content {
    padding-left: 100px;
  }


  /**
   * FOOTER
   */

  .footer { --fs-5: 1rem; }

  .footer-link-box { flex-basis: 700px; }

}