.common__section_cars {
  background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6)),
    url("../images/car/drive.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0px;
}

.common__section_land {
  background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6)),
    url("https://apnapyaraghar.com/wp-content/uploads/2023/07/Plot-For-Sale-in-Jayanagar-.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0px;
}

.common__section_house {
  background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6)),
    url("../images/property-3.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0px;
}

.common__section_contact {
  background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6)),
    url("https://plus.unsplash.com/premium_photo-1664392265223-755c0cffec39?q=80&w=1940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0px;
}

